
<template>
    <!-- <div v-if="s_instrumentsHistory"> 
      <span v-for="(d, index) in s_instrumentsHistory" v-bind:key="'s_i_1'+index">
        <span v-if="d.data">
            <span v-for="(d1, index1) in d.data" v-bind:key="'s_v_2'+index1">{{ d1.HIGH }} ,</span>
        </span>
      <br>----------------------------------------------------------------------------------------------------<br>
      </span>
      <router-link to="/vuexdata">vuexdata</router-link>
    </div> -->
      <!-- <router-link to="/vuexdata">vuexdata</router-link> -->
<div></div>
</template>

<script>
import sidebar from "../views/sidebar.vue";
import { mapState } from "vuex";
export default {
  name: 'vuexdata',
  data: () => ({
    authCode: "",
  }),

  components: { sidebar },

  computed: {
    ...mapState("userDetails", ["userData", "vendorApiKey"],['s_instrumentsHistory']),
  //   computed: mapState([ //2
  //   's_instrumentsHistory'
  // ]),
  },

  methods: {
    // async initialCall() {
    //   var authCode = this.$route.query.authCode;
    //   console.log(this.$route.query)
    //   if (authCode) {
    //     localStorage.setItem("authCode", authCode);
    //     await this.$store.dispatch("userDetails/getUserDetails");
    //   }
    // },
  },

  mounted() {
    // this.initialCall();
  },
};
</script>

<style>
</style>
